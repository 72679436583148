import React from 'react';
import { Select } from 'antd';
import { IOption } from 'common/models';

interface IComponentProps {
  title: string;
  options?: IOption[];
  value?: string | number;
  onChange: (value: string | number) => void;
}

const ListComponent: React.FC<IComponentProps> = ({ title, options, value, onChange }) => {
  return (
    <div className="filter__list">
      <div className="filter__list_title">{title}</div>

      <Select className="filter__list_selector" options={options} value={options ? value : undefined} onSelect={onChange} />
    </div>
  );
};

export const List = ListComponent;
