import React from 'react';
import type { RangePickerProps } from 'antd/es/date-picker';
import { DatePicker as AntdDatePicker } from 'antd';
import moment from 'moment';
import { EDateFormat, ESearchParams } from 'common/const/enums.const';

const { RangePicker } = AntdDatePicker;

interface IComponentProps {
  dateFrom?: string;
  dateTo?: string;
  onChange: (values: { [key: string]: string | undefined }[]) => void;
}

export const DatePicker: React.FC<IComponentProps> = ({ dateFrom, dateTo, onChange }) => {
  const handleDateChange: RangePickerProps['onChange'] = (values) => {
    const newDateFrom = values?.[0] ? values[0].startOf('day').toISOString() : undefined;
    const newDateTo = values?.[1] ? values[1].endOf('day').toISOString() : undefined;

    onChange([{ [ESearchParams.DateFrom]: newDateFrom }, { [ESearchParams.DateTo]: newDateTo }]);
  };

  return (
    <RangePicker
      placeholder={['Date', '']}
      ranges={{
        Today: [moment(), moment()],
        'Last Week': [moment().subtract(1, 'weeks'), moment()],
        'Last Month': [moment().subtract(1, 'months'), moment()],
      }}
      suffixIcon={<div className="icon datepicker-suffix" />}
      format={EDateFormat.FullYear}
      onChange={handleDateChange}
      defaultValue={[dateFrom?.length ? moment(dateFrom) : null, dateTo?.length ? moment(dateTo) : null]}
    />
  );
};
