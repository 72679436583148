import React from 'react';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';
import { EDateFormat } from 'common/const/enums.const';
import { ICarePlanItem } from 'entities/CarePlan/CarePlan.models';

export const getTreatmentDays = (startDate?: string) => {
  return startDate ? moment().diff(startDate, 'days') : '-';
};

export const renderCarePlanRecords = () => {
  return [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: (_, record) => <a onClick={() => window.open(record.link, '_blank')}>{record.id}</a>,
    },
    {
      title: 'Sales Manager',
      dataIndex: 'salesManager',
      key: 'salesManager',
      render: (_, record) => (record.salesManager ? record.salesManager : '-'),
    },
    {
      title: 'Device Type',
      dataIndex: 'deviceType',
      key: 'deviceType',
      render: (_, record) => (record.deviceType ? record.deviceType : '-'),
    },
    {
      title: 'Program',
      dataIndex: 'program',
      key: 'program',
      render: (_, record) => (record.program ? record.program : '-'),
    },
    {
      title: 'Treatment Area',
      dataIndex: 'treatmentArea',
      key: 'treatmentArea',
      render: (_, record) => (record.treatmentArea ? record.treatmentArea : '-'),
    },
    {
      title: 'Treatment Location',
      dataIndex: 'treatmentLocation',
      key: 'treatmentLocation',
      render: (_, record) => record.treatmentLocation ?? '-',
    },
    {
      title: 'Treatment Days',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (_, record) => getTreatmentDays(record.startDate),
    },
    {
      title: 'CoC Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (_, record) => (record.startDate ? moment(record.startDate).format(EDateFormat.ShortYear) : '-'),
    },
    {
      title: 'CoC End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (_, record) => (record.endDate ? moment(record.endDate).format(EDateFormat.ShortYear) : '-'),
    },
    {
      title: 'Ship Link',
      dataIndex: 'shippingLink',
      key: 'shippingLink',
      render: (_, record) =>
        record.shippingLink ? <a onClick={() => window.open(record.shippingLink, '_blank')}>Ship Link</a> : '-',
    },
  ] as ColumnsType<ICarePlanItem>;
};
